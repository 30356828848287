export const hexToRgb = (hex) => {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const FEET_IN_METER = 3.280839895;
const LB_FEET_IN_KG_METER = 0.062427960576145;
const TON_IN_TONNE = 1.10231131;
const TON_FEET_IN_TONNE_METER = 0.031213980288072;

type ConversionMethod = (value: number, precision: number) => number;

export const unitConversionMethods: {
  [key: string]: ConversionMethod;
} = {
  m: (value: number, precision: number): number => {
    return Number((value * FEET_IN_METER).toFixed(precision));
  },
  "m^2": (value: number, precision: number): number => {
    return Number((value * Math.pow(FEET_IN_METER, 2)).toFixed(precision));
  },
  "m^3": (value: number, precision: number): number => {
    return Number((value * Math.pow(FEET_IN_METER, 3)).toFixed(precision));
  },
  "%": (value: number, precision: number): number => {
    return Number(value.toFixed(precision));
  },
  "kg/m^3": (value: number, precision: number): number => {
    return Number((value * LB_FEET_IN_KG_METER).toFixed(precision));
  },
  t: (value: number, precision: number): number => {
    return Number((value * TON_IN_TONNE).toFixed(precision));
  },
  "t/m^3": (value: number, precision: number): number => {
    return Number((value * TON_FEET_IN_TONNE_METER).toFixed(precision));
  },
  count: (value: number, precision: number): number => {
    return Number(value.toFixed(precision));
  },
};
