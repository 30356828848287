export function PimentoPost(
  route: string,
  body?: any,
  options: { error: string } = { error: "" }
): Promise<any> {
  return (
    fetch(route, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...body,
      }),
    })
      // Pass HTTP status code and parsed response together
      // (there's no way to access the status after `res.json` otherwise)
      .then((res) => ({ status: res.status, response: res.json() }))
      .then((res) => {
        if (res.status >= 400) {
          throw new Error(
            options.error ||
              "" +
                ` ${res.status} error: ${
                  (res.response as any).error ||
                  "(No error message from server)"
                }`
          );
        }

        // Return the unwrapped response if there is no error
        return res.response;
      })
      .then((res) => {
        if (res.error) {
          console.error(options.error, res.error);
        }
        return res;
      })
      .catch((err) => {
        console.error(err);
        return err;
      })
  );
}

export function PimentoPut(
  route: string,
  body?: any,
  options: { error: string } = { error: "" }
): Promise<any> {
  return (
    fetch(route, {
      method: "PUT",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...body,
      }),
    })
      // Pass HTTP status code and parsed response together
      // (there's no way to access the status after `res.json` otherwise)
      .then((res) => ({ status: res.status, response: res.json() }))
      .then((res) => {
        if (res.status >= 400) {
          throw new Error(
            options.error ||
              "" +
                ` ${res.status} error: ${
                  (res.response as any).error ||
                  "(No error message from server)"
                }`
          );
        }

        // Return the unwrapped response if there is no error
        return res.response;
      })
      .then((res) => {
        if (res.error) {
          console.error(options.error, res.error);
        }
        return res;
      })
      .catch((err) => {
        console.error(err);
        return err;
      })
  );
}

export function PimentoDelete(
  route: string,
  options: { error: string } = { error: "" }
) {
  return (
    fetch(route, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      // Pass HTTP status code and parsed response together
      // (there's no way to access the status after `res.json` otherwise)
      .then((res) => ({ status: res.status, response: res.json() }))
      .then((res) => {
        if (res.status >= 400) {
          throw new Error(
            options.error ||
              "" +
                ` ${res.status} error: ${
                  (res.response as any).error ||
                  "(No error message from server)"
                }`
          );
        }

        // Return the unwrapped response if there is no error
        return res.response;
      })
      .then((res) => {
        if (res.error) {
          console.error(options.error, res.error);
        }
        return res;
      })
      .catch((err) => {
        console.error(err);
        return err;
      })
  );
}
